import React from 'react'
import BreadCrumb from '../components/BreadCrumb'
import Meta from '../components/Meta'
import Container from '../components/Container'

const PrivacyPolicy = () => {
  return (
    <>
        <Meta title={"Privacy Policy"}/>
        <BreadCrumb title="Privacy Policy" />  
        <Container class1='policy-wrapper py-5 home-wrapper-2'>
              <div className='row'>
                  <div className='policy'>
                      
                  </div>
              </div>
        </Container>
    </>
  )
}

export default PrivacyPolicy
